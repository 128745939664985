<template>
    <div class="python-container">
        <div class="content">
            <!-- TODO：此处的isShowTask控制参数，后续再做优化 -->
            <div v-show="isShowTask">
                <div id="task-area" class="task-area" v-if="isShowTask">
                    <!-- <div v-html="instructionHtml"></div> -->
                    <div class="btn-collapse" @click.stop="hideTask()">
                        <img :src="img.collapse" alt="" />
                    </div>
                    <div class="task-title">
                        <img :src="img.require" alt="" />
                        <div class="text">任务</div>
                    </div>
                    <div class="task-content">{{ task.title }}</div>
                    <div class="task-title">
                        <img :src="img.goal" alt="" />
                        <div class="text">目标</div>
                    </div>
                    <div class="task-content">{{ task.content }}</div>
                </div>
                <div class="task-area-mini" @click.stop="showTask" v-else>
                    <div class="btn-collapse">
                        <img :src="img.collapse" alt="" />
                    </div>
                </div>
            </div>

            <div class="code-area">
                <div class="file-tab">
                    <div class="tab active">main.py</div>
                    <!-- <div class="btn-zoom" @click.stop="toggleTask()">
                        <img :src="img.zoom" alt="" />
                    </div> -->
                </div>
                <div class="code-editor-area">
                    <AceEditor ref="editor"></AceEditor>
                </div>
                <div class="cmd-area">
                    <div
                        class="btn-run"
                        @click.stop="play()"
                        v-if="!isRunning"
                    >
                        运行
                    </div>
                    <div id="btn-stop" class="btn-stop" v-else @click.stop="stop()">
                        停止
                    </div>
                </div>
            </div>

            <div class="output-area">
                <div class="output-tab">
                    <v-chip class="tag ma-2" color="primary" label small>
                        {{ maxOutputArea == 'turtle' ? '海龟图' : '输出结果' }}
                    </v-chip>
                </div>
                <div class="terminal-area-wrapper" v-show="maxOutputArea != 'turtle'" >
                    
                    <XTerm ref="xterm" 
                        :isRunning="isRunning"
                        :inputResolve="inputResolve"
                        @play="play"
                        @stop="stop"
                    ></XTerm>

                    <div
                        class="btn-max"
                        @click.stop="setMaxOutputArea('result')"
                    >
                        <img src="@/assets/images/python/btn-max-white.svg" alt="" />
                    </div>
                </div>
                <div class="turtle-area-wrapper" v-show="maxOutputArea != 'result'" >
                    <v-chip class="tag ma-2" color="primary" label small
                        >海龟图</v-chip
                    >
                    <div id="turtle-area" class="turtle-area"></div>
                    <!-- <div
                        class="btn-max"
                        @click.stop="setMaxOutputArea('turtle')"
                    >
                        <img src="@/assets/images/python/btn-max-black.svg" alt="" />
                    </div> -->
                </div>
            </div>
        </div>

        <Loading></Loading>

        <ResetClassroomDataDlg ref="resetDataDlg" @confirm="confirmResetDataDlg"></ResetClassroomDataDlg>

        <HelpFuncListDlg ref="helpFuncListDlg"></HelpFuncListDlg>

        <!-- 任务说明 -->
        <div class="dlg-wrapper" v-if="bShowInstruction" @click.stop="closeInstruction()">
            <div class="operate-instruction-container" @click.stop="noop()">
                <div class="pic">
                    <img :src="getExamQuestionImgUrl(paper.curQuestion.question_img)" v-if="paper && paper.curQuestion.question_img">
                </div>
                <div class="instruction">
                    <div class="title" style="margin-bottom: 20px">【任务名称】{{this.paper.curQuestion.question_name}}</div>
                    <div class="title">【任务目标】</div>
                    <div v-for="(content, index) in getInstructions" v-bind:key="index">
                        <div class="content">{{ content }}</div>
                    </div>
                </div>
                <div class="window-close">
                    <img :src="img.closeWhite" @click="closeInstruction()">
                </div>
            </div>
            <!-- <div class="btn-start" @click.stop="closeInstruction()">开始操作</div> -->
        </div>

        <!-- 交卷弹窗提示 -->
        <div class="dlg-wrapper" v-show="bShowHandInDlg" @click.stop="closeHandInDlg()">
            <div class="hand-in-container" @click.stop="noop()">
                <div class="tip">确认交卷后考试会立即结束</div>
                <!-- <div class="tip" style="margin-bottom: 25px;">你确定要现在交卷吗？</div> -->
                <div class="tip" style="margin-bottom: 25px;">请确认操作题试题已保存</div>
                <div class="op">
                    <div class="btn-op cancel" @click.stop="closeHandInDlg()">取消</div>
                    <div class="btn-op ok" @click.stop="handIn()">现在交卷</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    /* eslint-disable no-undef */
    import Loading from "@/views/components/mask/Loading";
    import XTerm from "@/views/components/module/XTerm";
    import AceEditor from "@/views/components/module/AceEditor";
    import ResetClassroomDataDlg from "@/views/components/dialog/ResetClassroomDataDlg"
    import HelpFuncListDlg from "@/views/components/dialog/HelpFuncListDlg"

    import $ from "jquery";
    import cos from "@/utils/cos";
    import { mapGetters } from 'vuex'

    // common
    import { getCourseInfo, getSectionInfo } from '@/utils/common'
    // web/admin同名，但需要区分的接口
    import { sendReleaseMsg, getClassroomData, resetClassroomData, saveMySectionRes } from '@/api/web'
    // web
    import { genResId, createProject } from '@/api/web'
    // admin
    import { getAdminSectionData, saveAdminSectionRes, getAdminPythonDemo } from '@/api/admin'
    // exam
    import { endExam, getQuestion4Admin } from "@/api/admin_exam";

    export default {
        name: "exam-python",
        data: function () {
            return {
                img: {
                    require:
                        "https://assets.koocoding.com/assets/python/require.svg",
                    task: "https://assets.koocoding.com/assets/python/task.svg",
                    goal: "https://assets.koocoding.com/assets/python/goal.svg",
                    collapse:
                        "https://assets.koocoding.com/assets/python/collapse.svg",
                    zoom: "https://assets.koocoding.com/assets/python/zoom.svg",

                    closeWhite: "https://assets.koocoding.com/ide/images/close_white.png",
                },

                curEnv: '',

                resId: "",
                userResId: "",

                isRunning: false,
                curOutput: "console",

                maxOutputArea: "result", // both, result, turtle

                inputResolve: null,
                externalLibs: {
                    // "./mod/__init__.js": "./mod.js",
                    "./kgame/__init__.js": '/libs/mod/kgame.js',
                },

                task: {
                    title: "",
                    content: "",
                },
                isShowTask: false,

                instruction: "", // 使用marked支持Markdown
                instructionHtml: "",

                examId: '',
                qid: '',
                bShowInstruction: false,
                bShowHandInDlg: false,
            };
        },
        beforeRouteLeave: function(to, from , next){
            // let value = window.confirm("确定要离开当前页面吗？");
            // if (value == true) {
            //     // 确定
            //     next()
            // } else {
            //     // 取消
            //     next(false)
            // }
            
            // 检测到页面离开时，自动保存
            this.save()

            next()
        },
        computed: {
            ...mapGetters([
                'pid',
                'cid',
                'sid',
                'userInfo',
                'projInfo',
                'pyCurrent',
                'pyDemoIds',
                'pyCurDemoId',
                'paper',
            ]),
        },
        created() {
            window.onbeforeunload = function () {
                return "确定要离开当前页面吗？";
            };
        },
        mounted() {
            // 加载userInfo
            this.loadUserInfo();

            cos.init();
        },
        activated() {
            window.addEventListener("resize", this.resizeScreen);
            window.addEventListener('keydown', this.keyDown)
        },
        deactivated() {
            window.removeEventListener('keydown', this.keyDown)
            window.removeEventListener("resize", this.resizeScreen);
        },
        methods: {
            getCourseInfo,
            getSectionInfo,
            async loadUserInfo() {
                await this.$store.dispatch('user/getUserInfo');
            },
            reset() {
                this.$store.commit('web/ResetProjectInfo')
                this.$store.commit('web/ResetSectionInfo')

                this.resId = ""
                this.userResId = ""
                this.isRunning = false

                this.$refs.xterm.clear()
                this.clear()
            },
            loadPage(examId, qid, curEnv = null) {
                this.reset()

                if (curEnv) {
                    this.curEnv = curEnv
                }
                this.examId = examId
                this.qid = qid

                // 加载题目信息
                this.loadQuestion()
                // 加载程序数据
                this.loadExamData(qid)
            },
            loadExamData(pid) {
                this.$store.commit('web/SetProjectId', pid)

                // 加载数据
                if (this.pid != '') {
                    this.loadEditorContent(pid);
                }

                this.$store.commit("web/SetIsShowLoading", false);
            },
            async loadQuestion() {
                if (!this.paper.questionMap[this.qid]) {
                    let res = await getQuestion4Admin({
                        qid: this.qid,
                    });

                    this.$store.commit("exam/SetExamQuestionData", {
                        qid: this.qid,
                        question: res.data.question,
                    });
                }
                this.$store.commit('exam/UpdateExamPaperIndex', this.qid);
            },
            async loadEditorContent(id) {
                this.$refs.xterm.clear()

                let data = await cos.getObject('project', 'exam', id);
                if (data && data.Body) {
                    this.$refs.editor.setValue(JSON.parse(data.Body), -1);
                } else {
                    this.$refs.editor.setValue("")
                }

                // 加载完内容后，需要resize一下
                this.$refs.editor.resize()
                this.$refs.editor.focus()
            },
            keyDown(e) {
                // console.log(e)
                // if (e.keyCode == 27) {
                if (e.key == 'Escape') {
                    this.esc()
                    e.preventDefault()
                }
                if ((e.ctrlKey || e.metaKey) && e.key === 's') {
                    this.save()
                    e.preventDefault()
                }
                if ((e.altKey || e.metaKey) && e.keyCode == 13) {
                    e.preventDefault()
                    if (!this.isRunning) {
                        this.play()
                    } else {
                        this.stop()
                    }
                }
            },
            handleCmd(data) {
                switch (data.cmd) {
                    case 'save':
                        this.save()
                        break
                    case 'release':
                        this.release()
                        break
                    case 'reset':
                        this.$refs.resetDataDlg.openDlg();
                        break
                    case 'help':
                        this.help()
                        break
                    case 'fontPlus':
                        this.fontPlus()
                        break
                    case 'fontMinus':
                        this.fontMinus()
                        break
                    case 'showExercise':
                        this.showExercise()
                        break
                    case 'showDemo':
                        this.showDemo()
                        break
                    // Exam
                    case 'showInstruction':
                        this.showInstruction()
                        break
                    case 'showSubmitDlg':
                        this.showSubmitDlg()
                        break
                }
            },
            setMaxOutputArea(area) {
                // console.log(area)
                if (this.maxOutputArea == area) {
                    // 此处需要先清空，然后再调整flag
                    $('#turtle-area').empty()
                    this.maxOutputArea = 'both'

                    // 因为放大重新缩小后，turtle区的canvas无法有效缩回，
                    // 采取折中方案：重新运行一遍
                    // this.play()

                } else {
                    this.maxOutputArea = area
                    // if (area == 'result') {
                    //     this.fitAddon.fit();
                    // }
                }
                // 更新窗口大小后刷新一下
                this.$nextTick(function() {
                    this.resizeScreen();
                })
            },
            getEditorContent() {
                return this.$refs.editor.getValue();
            },
            
            showTask() {
                this.isShowTask = true;
            },
            hideTask() {
                this.isShowTask = false;
            },
            toggleTask() {
                this.isShowTask = !this.isShowTask;
            },

            switchTab(tab) {
                this.curOutput = tab;
            },
            outf(text) {
                this.$refs.xterm.write(text)
            },
            builtinRead(file) {
                /**
                 *  file参数代表当前加载的模块路径，一个模块名会按照以下6种路径和优先级查找，假设模块名为mod
                 *  src/builtin/mod.js
                 *  src/builtin/mod/__init__.js
                 *  src/lib/mod.js
                 *  src/lib/mod/__init__.js
                 *  ./mod.js
                 *  ./mod/__init__.js
                 *  前面四种路径一把是skulpt匹配自带模块用的。
                 * */
                // console.log("Attempting file: " + Sk.ffi.remapToJs(file))

                // 匹配外部模块
                if (this.externalLibs[file] !== undefined) {
                    // 使用skulpt提供的promiseToSuspension，等待异步任务执行完才能继续
                    return Sk.misceval.promiseToSuspension(
                    fetch(this.externalLibs[file]).then(
                        function (resp){
                            // console.log(resp)
                            // console.log(resp.text())
                            return resp.text()
                        }
                    ));
                }

                if (Sk.builtinFiles === undefined || Sk.builtinFiles.files[file] === undefined) {
                    throw "File not found: '" + file + "'"
                }
                
                // 匹配不到外部模块再从内置模块找
                return Sk.builtinFiles.files[file];
            },
            runit() {
                let that = this

                let src = this.$refs.editor.getValue()
                let width = $('#turtle-area').width()
                let height = $('#turtle-area').height()

                // stop all over previous running codes
                Sk.execLimit = 0; // execution limit set to 0 will stop async
                this.xtermLineNum = 1;

                Sk.configure({
                    __future__: Sk.python3,
                    python3: true,
                    execLimit: Number.POSITIVE_INFINITY,
                    // execLimit: 20000, //运行时长（ms为单位）
                    output: this.outf,
                    read: this.builtinRead,
                    inputfun: (prompt) => {
                        return new Promise((resolve, reject) => {
                            that.inputResolve = resolve
                            that.$refs.xterm.write(prompt)
                        });
                    },
                    inputfunTakesPrompt: true,
                    killableWhile: true,
                    killableFor: true,
                });
                (Sk.TurtleGraphics || (Sk.TurtleGraphics = {})).target = "turtle-area";
                Sk.TurtleGraphics.width = width; 
                Sk.TurtleGraphics.height = height;

                // Sk.externalLibraries = {
                //     numpy : {
                //         path: '/static/primeronoo/skulpt/external/numpy/__init__.js'
                //     },
                //     matplotlib : {
                //         path: '/static/primeronoo/skulpt/external/matplotlib/__init__.js'
                //     },
                //     "matplotlib.pyplot" : {
                //         path: '/static/primeronoo/skulpt/external/matplotlib/pyplot/__init__.js'
                //     },
                //     "arduino": {
                //         path: '/static/primeronoo/skulpt/external/arduino/__init__.js'
                //     }
                // };

                // Sk.builtin.KeyboardInterrupt = function (args) {
                //     var o;
                //     if (!(this instanceof Sk.builtin.KeyboardInterrupt)) {
                //         o = Object.create(Sk.builtin.KeyboardInterrupt.prototype);
                //         o.constructor.apply(o, arguments);
                //         return o;
                //     }
                //     Sk.builtin.BaseException.apply(this, arguments);
                // };
                // Sk.abstr.setUpInheritance("KeyboardInterrupt", Sk.builtin.KeyboardInterrupt, Sk.builtin.BaseException);

                // var interruptHandler = function (susp) {
                //     if (Sk.hardInterrupt === true) {
                //         throw new Sk.builtin.KeyboardInterrupt('aborted execution');
                //     } else {
                //         return null; // should perform default action
                //     }
                // };

                var myPromise = Sk.misceval.asyncToPromise(function () {
                    that.isRunning = true;
                    return Sk.importMainWithBody("<stdin>", false, src, true);
                });
                myPromise.then(
                    function (mod) {
                        that.isRunning = false;
                        that.$refs.xterm.writeln("程序结束");
                        that.$refs.editor.focus();
                    },
                    function (err) {
                        if (err.tp$name === "TimeLimitError" && Sk.execLimit === 0) {
                            // that.outf('TimeLimitError' + '\r\n');

                        } else {
                            that.outf(err.toString() + '\r\n');

                            // // add error fix if possible
                            // if(err.tp$name && typeof err.tp$name === "string" && script.errorText[err.tp$name] !== undefined) {
                            //     console.log('Got String Error');
                            //     // var aid = ++script.unquieID;
                            //     // that.output.append('<p><span class="text-danger">' + that.escape(e) + '</span> <small><a tabindex="0" id="' + aid + '">(Hilfe)</a></small></p>');
                            //     // $('#' + aid).popover({
                            //     // content: script.errorText[e.tp$name],
                            //     //     title: e.tp$name,
                            //     //     trigger: 'focus'
                            //     // });
                            // } else {
                            //     // that.output.append('<p class="text-danger">' + this.escape(e) + '</p>');
                            //     console.log('Got Other Error');
                            // }
                        }
                        that.isRunning = false;
                        that.$refs.editor.focus()
                    }
                );
            },
            resizeScreen() {
                this.$refs.xterm.resize();
                this.$refs.editor.resize();
            },
            play() {
                // 清空输出屏幕
                this.$refs.xterm.clear();
                // 将焦点放在console，便于直接输入input数据
                this.$refs.xterm.focus();

                this.runit();
            },
            stop() {
                // stop all over previous running codes
                Sk.execLimit = 0; // execution limit set to 0 will stop async

                this.isRunning = false;

                this.$refs.xterm.stop();

                this.$refs.editor.focus();
            },
            clear() {
                // 清空Demo数据
                this.$store.commit('web/SetPythonDemo', null);
                this.$store.commit('web/SetPyCurDemoId', '');

                // 停止程序
                // this.stop();
                // 清空编辑区
                this.$refs.editor.setValue("");
                // 清空console
                this.$refs.xterm.clear()
                // 清空绘图区
                $("#turtle-area").empty();
            },

            // TODO: 临时处理 
            async save() {
                if (this.pyCurrent == 'exercise') {
                    this._save()
                
                } else if (this.pyCurrent == 'demo') {
                    let data = this.getEditorContent();

                    if (this.pyDemoIds.length > 0) {
                        if (this.pyCurDemoId != '') {
                            await cos.putObject('project', 'project', this.pyCurDemoId, JSON.stringify(data));

                            this.$store.dispatch('web/SetAlert', {type: 'success', msg: '保存成功'});
                        }
                    }
                }
            },
            async _save() {
                // 保存项目数据
                let data = this.getEditorContent();

                if (this.curEnv == 'classroom') {
                    if (this.userResId == '') {
                        let res = await genResId()
                        this.userResId = res.data.resId;
                    }
                    await cos.putObject('project', 'project', this.userResId, JSON.stringify(data));
                    saveMySectionRes({ cid: this.cid, sid: this.sid, id: this.userResId })

                } else if (this.curEnv == 'editor') {
                    if (this.userResId == '') {
                        let res = await genResId()
                        this.resId = res.data.resId
                    }
                    await cos.putObject('project', 'project', this.resId, JSON.stringify(data))
                    saveAdminSectionRes({ cid: this.cid, sid: this.sid, resId: this.resId })

                } else if (this.curEnv == 'ide') {
                    // Admin中应该不会进入这个分支
                    if (this.pid != '') {
                        await cos.putObject('project', 'project', this.pid, JSON.stringify(data));
                    
                    } else {
                        let res = await createProject({
                            name: this.projInfo.name,
                            type: 'PYTHON',
                        })
                        // console.log(res)
                        if (res && res.data) {
                            let pid = res.data.pid

                            this.$store.commit('web/SetProjectId', pid)

                            this.$router.replace({ query: { id: pid } });

                            await cos.putObject('project', 'project', this.pid, JSON.stringify(data));
                        }
                    }
                } else if (this.curEnv == 'exam') {
                    if (this.pid != '') {
                        await cos.putObject('project', 'exam', this.pid, JSON.stringify(data));
                    }
                }
                this.$store.dispatch('web/SetAlert', {type: 'success', msg: '保存成功'});
            },
            async release() {
                this.save()

                await sendReleaseMsg({
                    type: 'project',
                    category: 'python',
                    id: this.pid,
                    cid: ''
                });

                this.$store.dispatch('web/SetAlert', {type: 'success', msg: '发布成功'});
            },
            confirmResetDataDlg() {
                resetClassroomData({
                    cid: this.cid,
                    sid: this.sid
                });

                if (this.resId) {
                    this.loadEditorContent(this.resId);
                }
                this.$store.dispatch('web/SetAlert', {type: 'success', msg: '重置成功'});
            },
            help() {
                this.$refs.helpFuncListDlg.switchDlg()
            },
            esc() {
                this.$refs.helpFuncListDlg.closeDlg()
            },
            fontPlus() {
                let fontSize = this.$refs.editor.getFontSize()
                let size = parseInt(fontSize.substring(0, fontSize.length - 2))
                if (size < 28) {
                    size += 2
                    this.$refs.editor.setFontSize(size.toString() + 'px')
                }
            },
            fontMinus() {
                let fontSize = this.$refs.editor.getFontSize()
                let size = parseInt(fontSize.substring(0, fontSize.length - 2))
                if (size > 12) {
                    size -= 2
                    this.$refs.editor.setFontSize(size.toString() + 'px')
                }
            },
            showExercise() {
                if (this.curEnv == 'classroom' || this.curEnv == 'editor') {
                    this.loadEditorContent(this.resId)
                } else {
                    this.loadEditorContent(this.pid)
                }
            },
            showDemo() {
                if (this.pyCurDemoId) {
                    this.loadEditorContent(this.pyCurDemoId);
                }
            },

            // For Exam
            getExamQuestionImgUrl(key) {
                if (key) {
                    return 'https://assets.exam.koocoding.com/exam/' + key
                }
                return '';
            },
            showInstruction() {
                this.bShowInstruction = true;
            },
            closeInstruction() {
                // this.screenfull()
                this.bShowInstruction = false;
            },
            showSubmitDlg() {
                this.bShowHandInDlg = true;
            },
            closeHandInDlg() {
                this.bShowHandInDlg = false;
            },
            async handIn() {
                await endExam(this.examId);
                this.$router.push({ path: '/exam/end' });
            },
            noop() {},
        },
        components: {
            Loading,
            XTerm,
            AceEditor,
            ResetClassroomDataDlg,
            HelpFuncListDlg
        },
    };
</script>

<style lang="scss" scoped>
.python-container {
    height: 100%;
    width: 100%;
    display: flex;
}

.content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .task-area {
        // display: flex;
        // flex: 1;
        position: relative;
        width: 350px;
        height: 100%;
        // padding: 10px;
        // background-color: #fdf6e3;
        background-color: #ffffff;
        line-height: 1.8;
        letter-spacing: 1px;
        overflow-y: auto;
        // word-wrap: break-word;
        // word-break: break-word;
        // overflow-wrap: break-word;
        // font: 16px/normal 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;

        .btn-collapse {
            position: absolute;
            top: 0;
            right: 0;
            width: 45px;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            img {
                height: 18px;
                width: 18px;
            }
        }

        .task-title {
            // width: 100%;
            height: 45px;
            padding: 0 15px;
            background-color: #ebebeb;
            color: #292929;
            font-size: 16px;
            font-weight: bold;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            img {
                height: 18px;
                width: 18px;
                margin-right: 8px;
            }
        }
        .task-img {
            padding: 10px 15px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .task-content {
            // width: 100%;
            // min-height: 45px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            font-size: 15px;
            margin: 15px;
            text-align: left;
        }
    }
    .task-area-mini {
        width: 45px;
        background-color: #c5c6c8;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        cursor: pointer;
        .btn-collapse {
            width: 100%;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            img {
                height: 18px;
                width: 18px;
            }
        }
    }
    .task-area-mini:hover {
        background-color: #ffffff;
    }

    .code-area {
        height: 100%;
        display: flex;
        flex: 2;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        // background-color: #1f1e2e;
        background-color: #252527;
        position: relative;
        border-right: 1px solid #444;

        .file-tab {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 40px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            // background-color: #111019;
            background-color: #27243a;
            // background-color: #1f1e2e;
            // padding-left: 15px;
            .tab {
                font-size: 16px;
                height: 100%;
                width: 120px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                color: #ffffff;
                cursor: pointer;
                &.active {
                    // background-color: #1f1e2e;
                    background-color: #373352;
                }
            }
            .btn-zoom {
                position: absolute;
                top: 0;
                right: 0;
                width: 45px;
                height: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                img {
                    height: 18px;
                    width: 18px;
                }
            }
            .btn-zoom:hover {
                background-color: #1d1c2b;
            }
        }

        .code-editor-area {
            position: absolute;
            top: 45px;
            bottom: 45px;
            left: 0;
            right: 0;
            display: flex;
        }

        .cmd-area {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 45px;
            box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.2);
            background-color: #201e2f;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .btn-run {
                height: 100%;
                width: 80px;
                background-color: #4b35ef;
                font-size: 16px;
                font-weight: bold;
                color: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
            .btn-stop {
                height: 100%;
                width: 80px;
                background-color: #ce401c;
                font-size: 16px;
                font-weight: bold;
                color: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    .output-area {
        flex: 1;
        min-width: 500px;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        // background-color: #1c1b25;
        background-color: #1e1e1e;
        position: relative;

        .output-tab {
            width: 100%;
            height: 40px;
            background-color: #27243a;
            // background-color: #1e1e1e;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        // xterm输出
        .terminal-area-wrapper {
            position: relative;
            flex: 1;
            width: 100%;
            // height: 100%;
            // background-color: #1c1b25;
            background-color: #1e1e1e;
            color: #ffffff;
            border: none;
            overflow: hidden;
            
            .terminal-area {
                position: absolute;
                top: 3px;
                bottom: 60px;
                left: 8px;
                right: 8px;
            }
        }
        // 海龟图
        .turtle-area-wrapper {
            flex: 1;
            position: relative;
            // width: 100%;
            // height: 100%;
            background-repeat: unset;
            background-color: #ffffff;
            background-image:
                    linear-gradient(45deg, #efefef 25%, transparent 0, transparent 75%, #efefef 0),
                    linear-gradient(45deg, #efefef 25%, transparent 0, transparent 75%, #efefef 0);
            background-position: 0 0, 15px 15px;
            background-size: 30px 30px;
            .tag {
                position: absolute;
                left: 10px;
                top: 5px;
            }
            .turtle-area {
                width: 100%;
                height: 100%;
            }
        }
        .turtle-area:focus {
            outline: none;
        }

        .btn-max {
            position: absolute;
            right: 10px;
            bottom: 8px;
            cursor: pointer;
            z-index: 100;
            img {
                height: 26px;
                width: 26px;
            }
        }
    }
}
</style>

<style lang="scss" scoped>
// 用于测评
.dlg-wrapper {
    position: fixed;
    z-index: 2100;
    // top: 70px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(50, 50, 50, 0.95);
    // border-top: 1px solid #222;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // color: #efefef;
    color: #555;
}

.operate-instruction-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 35px 35px;
    border-radius: 8px;
    position: relative;
    .pic {
        width: 400px;
        margin-right: 30px;
        img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }
    .instruction {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        letter-spacing: 1px;
        max-width: 600px;
        .title {
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 12px;
        }
        .content {
            font-size: 18px;
            margin-bottom: 8px;
            display: flex;
            justify-content: flex-start;
            text-align: left;
        }
    }
    .window-close {
        position: absolute;
        top: 12px;
        right: 12px;
        height: 26px;
        width: 26px;
        cursor: pointer;
        img {
            height: 100%;
            width: 100%;
            background-color: #383838;
            border-radius: 50px;
            padding: 5px;
        }
    }
}
.btn-start {
    font-size: 18px;
    font-weight: bold;
    padding: 12px 24px;
    border: 2px solid #efefef;
    border-radius: 12px;
    background-color: #f4920f;
    margin: 50px 0;
    cursor: pointer;
}
.hand-in-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    padding: 25px 0 0 0;
    border-radius: 8px;
    color: #555;
    .tip {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
        width: 400px;
        height: 30px;
        margin-bottom: 10px;
        color: #f13421;
    }
    .op {
        width: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #ccc;
        .btn-op {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            line-height: 50px;
            font-size: 16px;
            font-weight: bold;
            flex: 1;
            cursor: pointer;
            &.cancel {
                border-right: 1px solid #ccc;
            }
            &.ok {
                color: #f13421;
            }
        }
    }
}
</style>
